import React from 'react';
import TopBar from '../components/Topbar';
import { ThemeProvider } from '@emotion/react';
import theme from '../theme';
import Footer from '../components/Footer';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Icon, Stack, Typography } from '@mui/material';
import backgroundImage from '../assets/mainphoto.png';
import SimpleMap from '../components/SimpleMap';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SpaIcon from '@mui/icons-material/Spa';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useNavigate } from 'react-router-dom';

// Import images
import placa1 from '../assets/placa1.png';
import placa2 from '../assets/placa2.png';
import placa3 from '../assets/placa3.png';

const data = [
    {
        title: 'Threshing in the month of July',
        description: 'After harvesting and tying the bundles of wheat, barley, chickpeas, or rye, they were brought to the threshing floor to separate the straw from the grain. It was an activity that families did together, where a donkey, mule, or horse would walk in circles pulling the threshing sled. Family members took turns riding on the sled to apply pressure and guide the animals. Arsenio tells us how, during the days of July, they spent the entire day threshing, which was the least enjoyable part of farming because it was very hot.',
        data: 'https://soundcloud.com/palosquevienendetras/trillar-arsenio?si=18260da6317440af9bb8944872a39fd1&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing',
        img: placa1,
        enabled: true
    },
    {
        title: 'Sowing cereals',
        description: 'Eu dolor amet commodo adipisicing officia nostrud aute reprehenderit dolore dolore fugiat pariatur. Incididunt proident consequat enim officia nisi fugiat velit cillum nulla sit tempor nisi. Magna nisi duis sint quis aliqua aute labore pariatur ad ut esse quis incididunt. Laboris cupidatat ullamco deserunt magna. Consectetur ut ut voluptate aute ut est.',
        data: 'https://soundcloud.com/gunna/collage',
        img: placa2,
        enabled: false
    },
    {
        title: 'Mowing and straw tying',
        description: 'Ipsum fugiat deserunt pariatur eu minim id esse aliqua sit amet cupidatat. Ullamco incididunt esse eiusmod ullamco culpa. Elit cupidatat aliquip irure in id nostrud. Veniam reprehenderit cupidatat ut nulla fugiat culpa proident nulla. Officia quis veniam dolore eu deserunt mollit cupidatat commodo mollit ipsum excepteur eu consequat. Dolore Lorem aliquip labore deserunt enim dolore quis.',
        data: 'https://soundcloud.com/gunna/collage',
        img: placa3,
        enabled: false
    },
    {
        title: 'Oil mill for centenary olive trees',
        description: 'Ipsum fugiat deserunt pariatur eu minim id esse aliqua sit amet cupidatat. Ullamco incididunt esse eiusmod ullamco culpa. Elit cupidatat aliquip irure in id nostrud. Veniam reprehenderit cupidatat ut nulla fugiat culpa proident nulla. Officia quis veniam dolore eu deserunt mollit cupidatat commodo mollit ipsum excepteur eu consequat. Dolore Lorem aliquip labore deserunt enim dolore quis.',
        data: 'https://soundcloud.com/gunna/collage',
        img: null,
        enabled: false
    },
    {
        title: 'The big watercourse',
        description: 'Ipsum fugiat deserunt pariatur eu minim id esse aliqua sit amet cupidatat. Ullamco incididunt esse eiusmod ullamco culpa. Elit cupidatat aliquip irure in id nostrud. Veniam reprehenderit cupidatat ut nulla fugiat culpa proident nulla. Officia quis veniam dolore eu deserunt mollit cupidatat commodo mollit ipsum excepteur eu consequat. Dolore Lorem aliquip labore deserunt enim dolore quis.',
        data: 'https://soundcloud.com/gunna/collage',
        img: null,
        enabled: false
    }
];

export default function Main(pins) {
    const navigate = useNavigate();

    return (
        <ThemeProvider theme={theme}>
            <TopBar />
            <Box sx={{ backgroundColor: "rgba(242, 246, 236, 1)" }}>
                <Typography variant="h3" sx={{ padding: 2, textAlign: 'left', fontFamily: "Epilogue-Black", color: "rgba(50, 22, 6, 1)" }}>
                    Garciotum, the heritage of our fields
                </Typography>
                <Typography variant="body1" component="p" sx={{ padding: 2, textAlign: 'left', fontFamily: "Epilogue-Regular", color: "rgba(50, 22, 6, 1)" }}>
                    Garciotum is our pilot project with which we started to build a sonorous testament of the fields.
                </Typography>
                <Box sx={{ width: '100%', height: '400px', backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'left' }} />
            </Box>
            <Box sx={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
                <Typography variant="h3" component="h1" sx={{ padding: 2, textAlign: 'left', fontFamily: "EBGaramond-Italic", color: "rgba(50, 22, 6, 1)" }}>
                    We are in a mission to build our heritage of the crop fields.
                </Typography>
                <Typography variant="body1" component="p" sx={{ padding: 2, textAlign: 'left', fontFamily: "Epilogue-Regular", color: "rgba(50, 22, 6, 1)" }}>
                    In Garciotum we don’t want that the memories of our crop fields disappear. We are in a mission to build our heritage of the crop fields.
                </Typography>
            </Box>
            <Box sx={{ backgroundColor: "rgba(242, 246, 236, 1)" }}>
                <Typography variant="h4" sx={{ padding: 2, textAlign: 'left', fontFamily: "EBGaramond-Italic" }}>
                    Memories
                </Typography>
                <Container sx={{ padding: 2, backgroundColor: "rgba(242, 246, 236, 1)" }}>
                    <SimpleMap {...pins} />
                </Container>
                {data.map((item, index) => (

                    <Accordion sx={{ boxShadow: 'none' }} key={index}>
                        <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                            <Icon>
                                <SpaIcon sx={{ color: 'rgba(50, 22, 6, 1)' }} />
                            </Icon>
                            <Typography variant="h6" sx={{ fontFamily: 'Epilogue-Medium', color: 'rgba(50, 22, 6, 1)' }}>
                                {item.title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {item.enabled ? <Stack direction="column" spacing={2} sx={{ alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ color: 'text.secondary', fontFamily: 'Epilogue-Regular' }}>
                                    {item.description}
                                </Typography>
                                <ReactPlayer url={item.data} width={'100%'} height={200} />
                            </Stack> :
                                (
                                    <Typography variant="body1" sx={{ color: 'text.secondary', fontFamily: 'Epilogue-Regular' }}>
                                        Comming soon
                                    </Typography>
                                )
                            }
                        </AccordionDetails>

                    </Accordion>
                ))}
            </Box>
            <Box sx={{ backgroundColor: "rgba(242, 246, 236, 1)" }}>
                <Typography variant="h6" sx={{ padding: 2, textAlign: 'left', fontFamily: "Epilogue-Black", color: "rgba(50, 22, 6, 1)" }}>
                    Memory plaques
                </Typography>
                <Carousel
                    showArrows
                    infiniteLoop
                    showThumbs={false}
                    swipeScrollTolerance={5}
                    autoPlay
                    interval={3000}
                >
                    {data.filter(item => item.img).map((item, index) => (
                        <div key={index}>
                            <img src={item.img} alt={item.title} style={{ width: '100%' }} />
                        </div>
                    ))}
                </Carousel>
            </Box>

            <Box sx={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
                <Typography variant="h3" component="h1" sx={{ padding: 2, textAlign: 'left', fontFamily: "EBGaramond-Italic", color: "rgba(50, 22, 6, 1)" }}>
                    We are in a mission to build our heritage of the crop fields.
                </Typography>
                <Typography variant="body1" component="p" sx={{ padding: 2, textAlign: 'left', fontFamily: "Epilogue-Regular", color: "rgba(50, 22, 6, 1)" }}>
                    Script, maps, templates, we share the resources with you so you don’t start from scratch.
                </Typography>

                <Box sx={{ padding: 2, textAlign: 'center' }}>
                    <Button variant="contained"
                        sx={{
                            textTransform: 'none',
                            padding: 2,
                            textAlign: 'center',
                            fontFamily: "Epilogue-SemiBold",
                            backgroundColor: "rgba(69, 31, 10, 1)",
                            fontSize: "1.5rem",
                        }}
                        onClick={() => navigate('/resources')}
                    >
                        Resources
                    </Button>
                </Box>
            </Box>
            <Footer />
        </ThemeProvider>
    );
}
