import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './pages/Main';
import Resources from './pages/Resources';
import { isMobile } from 'react-device-detect';
import InConstruction from './pages/InConstruction';

function App() {


  const stories = [
    {
      id: 1,
      lat: 40.098943,
      lng: -4.646769
    },
    {
      id: 2,
      lat: 40.097943,
      lng: -4.646769
    },
    // Add more stories as needed
  ];

  const pins = {
    center: {
      lat: 40.098943,
      lng: -4.646769
    },
    pins: stories.map(story => ({
      key: story.id,
      lat: story.lat,
      lng: story.lng,
    })),
    zoom: 16,
  };

  return (
    <div className="App">
      <BrowserRouter>
        {isMobile ? (
          <Routes>
            <Route path="/" element={<Main {...pins} />} />
            <Route path="/resources" element={<Resources />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<InConstruction />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
