import { BottomNavigation, Button } from '@mui/material'
import React from 'react'
import plants from '../assets/plants.png'

export default function Footer() {
    return (
        <BottomNavigation sx={{
            justifyContent: 'flex-end',
            paddingRight: 2,
            backgroundColor: "rgba(69, 31, 10, 1)",
            backgroundImage: `url(${plants})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            height: 100,
        }}>
            <Button color="inherit" sx={{ color: "white", fontFamily: "Epilogue-Regular", textTransform: 'none' }}>palosquevienendetras@gmail.com</Button>
        </BottomNavigation>
    )
}
