import { createTheme } from '@mui/material/styles';
import './assets/fonts/fonts.css'; // The path to your fonts CSS file

const theme = createTheme({
    typography: {
        fontFamily: [
            'Epilogue-Regular',
            'Epilogue-Medium',
            'Epilogue-SemiBold',
            'Epilogue-Bold',
            'Epilogue-ExtraBold',
            'Epilogue-Black',
            'EBGaramond-Regular',
            'EBGaramond-Medium',
            'EBGaramond-SemiBold',
            'EBGaramond-Bold',
            'EBGaramond-Italic',
        ].join(','),
    },
});

export default theme;