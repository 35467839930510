import React from 'react';
import { ThemeProvider } from '@emotion/react';
import theme from '../theme';
import TopBar from '../components/Topbar';
import Footer from '../components/Footer';
import { Accordion, AccordionDetails, AccordionSummary, Box, Icon, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SpaIcon from '@mui/icons-material/Spa';

import manual from '../assets/resources/manual.png';
import manual2 from '../assets/resources/manual2.png';

import materials from '../assets/resources/materials.png';

export default function Resources() {
    return (
        <ThemeProvider theme={theme}>
            <TopBar />
            <Box sx={{ backgroundColor: "rgba(242, 246, 236, 1)" }}>
                <Typography variant='h3' sx={{ padding: 2, textAlign: 'left', fontFamily: "Epilogue-Black", color: "rgba(50, 22, 6, 1)" }}>
                    DIY your heritage
                </Typography>
                <Typography variant='body1' sx={{ padding: 2, textAlign: 'left', fontFamily: "Epilogue-Regular", color: "rgba(50, 22, 6, 1)" }}>
                    To build the cultural heritage of the farm fields, we organized an activity for all the neighbors of the village.
                </Typography>
                <Typography variant='body1' sx={{ padding: 2, textAlign: 'left', fontFamily: "Epilogue-Regular", color: "rgba(50, 22, 6, 1)" }}>
                    It consisted of two parts, a series of interviews between members of different generations about the customs and traditions of the care of the fields. A final debate about the future of the fields.
                </Typography>
            </Box>
            <Box sx={{ backgroundColor: "white" }}>
                <Typography variant='h5' sx={{ padding: 2, textAlign: 'left', fontFamily: "Epilogue-Black", color: "rgba(50, 22, 6, 1)" }}>
                    1 Activity
                </Typography>
                <Typography variant='body1' sx={{ padding: 2, textAlign: 'left', fontFamily: "Epilogue-Regular", color: "rgba(50, 22, 6, 1)" }}>
                    The space can have the tables where the different interviews are being given, an information area and a rest area where people can be talking separately.
                </Typography>
            </Box>
            <Box sx={{
                backgroundImage: `url(${manual})`,
                backgroundSize: 'contain',  // Ensures the image fits within the container without cropping
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%', // Fits the width of the mobile device
                height: 0,
                paddingBottom: `${(2772 / 780) * 100}%`, // Maintain aspect ratio (height/width) * 100%
            }}>
            </Box>

            <Box sx={{ backgroundColor: "white" }}>
                <Typography variant='h5' sx={{ padding: 2, textAlign: 'left', fontFamily: "Epilogue-Black", color: "rgba(50, 22, 6, 1)" }}>
                    2 Round table
                </Typography>
                <Typography variant='body1' sx={{ padding: 2, textAlign: 'left', fontFamily: "Epilogue-Regular", color: "rgba(50, 22, 6, 1)" }}>
                    For the round table, rearrange the tables and chairs and place them so that all participants can see each other.
                </Typography>
            </Box>

            <Accordion sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                    <Icon>
                        <SpaIcon sx={{ color: 'rgba(50, 22, 6, 1)' }} />
                    </Icon>
                    <Typography variant="h6" sx={{ fontFamily: 'Epilogue-Medium', color: 'rgba(50, 22, 6, 1)' }}>
                        Materials
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={
                        {
                            backgroundImage: `url(${materials})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: '100%',
                            height: 0,
                            paddingBottom: `${(1531 / 686) * 100}%`,
                        }
                    }>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Box sx={{
                backgroundImage: `url(${manual2})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: 0,
                paddingBottom: `${(2623 / 780) * 100}%`,
            }}>

            </Box>
            <Footer />
        </ThemeProvider >
    );
}
