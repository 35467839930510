import React from 'react';
import AppBar from '@mui/material/AppBar';
import { useNavigate } from 'react-router-dom';
import { Toolbar, Typography, Button, Box, Stack } from '@mui/material';
import Logo from '../assets/logo.png';
import plants from '../assets/plants.png';

function TopBar() {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate('/');
    };

    const goToResources = () => {
        navigate('/resources');
    }

    return (
        <AppBar position="static">
            <Toolbar sx={{
                backgroundColor: "rgba(69, 31, 10, 1)",
                backgroundImage: `url(${plants})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
            }}>
                <Stack direction="column" spacing={2} sx={{
                    paddingTop: 2,
                    paddingBottom: 2,
                    width: "100%",
                }}>
                    <Stack direction="row" sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <img src={Logo} width={75} alt="Logo" />
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    color: "white",
                                    fontSize: "1.5rem",
                                    fontFamily: "Epilogue-Black",
                                    marginLeft: 1,
                                }}>
                                For those who follow
                            </Typography>
                        </Box>
                        {/* <Button color="inherit" sx={{ color: "white", fontFamily: "Epilogue-Black" }}>EN</Button> */}
                    </Stack>
                </Stack>
            </Toolbar>
            <Stack direction="row" sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                backgroundColor: "rgba(55, 25, 8, 1)",
            }}>
                <Button color="inherit" sx={{
                    color: "white", fontFamily: "Epilogue-Black", textTransform: 'none',
                }} onClick={goToHome}>Garciotum</Button>
                <Button color="inherit" onClick={goToResources} sx={{
                    color: "white", fontFamily: "Epilogue-Black", textTransform: 'none',
                }}>Resources</Button>
            </Stack>
        </AppBar >
    );
}

export default TopBar;
