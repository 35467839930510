import React from 'react';
import { ThemeProvider } from '@emotion/react';
import theme from '../theme';
import { Toolbar, Typography, Box, Stack } from '@mui/material';
import Logo from '../assets/logo.png';
import plants from '../assets/plants.png';

export default function InConstruction() {
    return (
        <ThemeProvider theme={theme}>
            <Toolbar sx={{
                backgroundColor: "rgba(69, 31, 10, 1)",
                backgroundImage: `url(${plants})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
            }}>
                <Stack direction="column" spacing={2} sx={{
                    paddingTop: 2,
                    paddingBottom: 2,
                    width: "100%",
                }}>
                    <Stack direction="row" sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <img src={Logo} width={75} alt="Logo" />
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    color: "white",
                                    fontSize: "1.5rem",
                                    fontFamily: "Epilogue-Black",
                                    marginLeft: 1,
                                }}>
                                For those who follow
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
            </Toolbar>
            <Box sx={{
                backgroundColor: "rgba(242, 246, 236, 1)",
                height: "100vh", // Full viewport height
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 2,
            }}>
                <Stack spacing={2}>
                    <Typography variant='h1' sx={{ fontFamily: "Epilogue-Black", color: "rgba(50, 22, 6, 1)" }}>
                        In Construction
                    </Typography>
                    <Typography variant='h3' sx={{ fontFamily: "Epilogue-Regular", color: "rgba(50, 22, 6, 1)" }}>
                        Currently, we only support mobile devices.
                    </Typography>
                </Stack>
            </Box>
        </ThemeProvider>
    )
}
