import React from 'react';
import { useNavigate } from 'react-router-dom';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

function SimpleMap(props) {
    const navigate = useNavigate();

    const handleClick = (key) => {
        navigate(`/story/${key}`);
    };

    return (
        <APIProvider apiKey={API_KEY}>
            <Map
                id="map"
                style={{ width: '100%', height: '400px' }}
                defaultCenter={{ lat: props.center.lat, lng: props.center.lng }}
                defaultZoom={props.zoom}
                gestureHandling='greedy'
                mapTypeId='hybrid'
                disableDefaultUI
            >
                {props.pins.map((point) => (
                    <Marker
                        position={{ lat: point.lat, lng: point.lng }}
                        key={point.key}
                        onClick={() => handleClick(point.key)}
                    />
                ))}
            </Map>
        </APIProvider>
    );
}

export default SimpleMap;
